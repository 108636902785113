const callouts = [
  {
    id: 1,
    active: true,
    name: 'دوره شیپ آپ',
    nameEn: 'Shape up 7',
    description: 'دوره کامل تغذیه و ورزش',
    descriptionColor: 'gold',
    date: 'شروع دوره -آذر ۱۴۰۳',
    price: '125',
    currency: 'EUR',
    paymantUrl: '/signup',
    sign: '€',
    priceRial: '4500000',
    priceRialSign: '۴،۵۰۰،۰۰۰',
    period: 'دوره سه ماهه',
    imageSrc: require('../img/azi.jpg'),
    href: '/shapeup',
  },
  {
    id: 2,
    active: true,
    name: 'آکادمی شیپ آپ',
    nameEn: 'Shape up Academy',
    description: 'دوره کامل تغذیه ',
    descriptionColor: 'gold',
    date: 'شروع دوره از ۱ اسفند ۱۴۰۲',
    price: '49',
    currency: 'EUR',
    paymantUrl: '/signup',
    sign: '€',
    priceRial: '1999000',
    priceRialSign: '۱،۹۹۹،۰۰۰',
    period: 'نامحدود',
    imageSrc: require('../img/academy.png'),
    href: '/academy',
  },
  {
    id: 3,
    active: true,
    name: 'جلسات اختصاصی کوچینگ تغذیه',
    nameEn: 'Nutrition coaching',
    product_id: 'price_1MqZzJAB6MVrXxqzsXY6cSv3',
    description: ' به همراه اپلیکیشن ورزشی',
    descriptionColor: 'gold',

    price: '189.99',
    priceRial: '10000000',
    priceRialSign: '۱۰،۰۰۰،۰۰۰',
    paymantUrl: 'https://buy.stripe.com/fZebJYanGcVE9IQbJ6',
    currency: 'EUR',
    sign: '€',

    period: 'ماهیانه',
    features: [
      {
        id: 1,
        details: 'چهار جلسه خصوصی آنلاین از طریق ویدیو کال',
      },
      {
        id: 2,
        details: 'مشاوره تغذیه و لایف استایل اختصاصی',
      },
      {
        id: 3,
        details: 'ارسال پیام به پشتیبان از طریق اپلیکیشن  ',
      },
      {
        id: 4,
        details: 'ثبت وزن، دور کمر و دور باسن و مشاهده پیشرفت',
      },
      {
        id: 5,
        details: 'محاسبه BMI و مشاهده پیشرفت    ',
      },
      {
        id: 6,
        details: 'آپدیت ماهیانه تمرین ها بصورت اتوماتیک',
      },
      {
        id: 7,
        details: 'امکان جایگزینی تمرین ها',
      },
      {
        id: 8,
        details: 'تمرینهای متناسب با خانه و باشکاه',
      },
    ],
    href: '/private',
    imageSrc:
      'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg',
    imageAlt:
      'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
  },
];

export default callouts;
